*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-weight: normal;
  font-family: "Roboto", sans-serif;
}
