.login-wrapper {
  width: 300px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-box {
  padding: 20px 20px;
}

.logo-button:hover {
  border-radius: 0px;
  background-color: transparent !important;
  border-bottom: 2px solid black;
}
