@import "./grid";

// DISABLED INPUTS
.MuiFormControl-root {
  .MuiInputLabel-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.6);
  }

  .MuiInput-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.87);
    &::before {
      border: 0 !important;
    }
    .MuiInput-input {
      color: rgba(0, 0, 0, 0.87);
      -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
    }
  }
}

// LOGS TABLE
.MuiTable-root.logs-table {
  .MuiTableHead-root {
    .MuiTableRow-root {
      .MuiTableCell-root {
        background-color: #f8f8ff;
      }
    }
  }
}
