#main-home {
  .paper-title {
    font-size: $font14;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
  .paper-value {
    font-size: 2.125rem;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
  }
}
